import { RepositoryFactory } from "../../repository/RepositoryFactory";
var train = RepositoryFactory.get("train");
export const checkUsernameAction = (payload) => async (dispatch) => {
    try {
        const { data } = await train.checkUsername(payload);
        console.log(data, "data");
        if (data != undefined) {
            dispatch({
                type: "checkUsername",
                payload: data,
            });
        } else {
            alert("Sorry Response Failed!");
        }
    } catch (error) {
        console.error("Error logging out:", error);
    }
};
export const createChatBot =
    (payload, user_id, onSuccess) => async (dispatch) => {
        try {
            const formData = new FormData();
            payload?.logo?.forEach((item) => {
                formData.append("file", item.originFileObj);
            });

            const suggestionQuestionsString =
                payload?.suggestionQuestions.join(",");

            const queryParams = new URLSearchParams({
                id: "1",
                chatbot_name: payload?.chatBotName,
                username: payload?.username,
                user_id: user_id,
                category: payload?.chatBotFor?.title,
                welcome_msg: payload?.welcomeMessage,
                background_color: payload?.background?.chatbotName,
                chatbot_color: payload?.background?.botBackground,
                text_front_color: payload?.background?.botBackground,
                text_back_color: payload?.background?.userBackground,
                additional_rule: payload?.additionalRule,
                email: payload?.email,
                suggestion_q: suggestionQuestionsString,
            });

            const url = `http://192.168.18.86:8000/chatbots/create-chatbot?${queryParams}`;

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    token: getToken(),
                },
                body: formData,
            });

            if (response.ok) {
                onSuccess();
            } else {
                alert("Sorry ChatBot Created Failed!");
            }
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };
const getToken = () => {
    try {
        const tokenString = JSON.parse(localStorage.getItem("access_token"));
        if (tokenString) {
            return tokenString;
        }
    } catch (error) {
        console.log("Error parsing localStorage:", error);
    }
    return "";
};
export const clearUsernameAction = () => async (dispatch) => {
    try {
        dispatch({
            type: "clearUsername",
            payload: null,
        });
    } catch (error) {
        console.error("Error logging out:", error);
    }
};
