import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Col, Container, Row } from "reactstrap";

const Main = (props) => {
    const history = useHistory();
    const location = useLocation();
    const storedToken = JSON.parse(localStorage.getItem("access_token"));
    console.log(storedToken, "storedToken");
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const pathname = location?.pathname.split("/").pop();
    const capitalizedPathname = capitalizeFirstLetter(pathname);
    useEffect(() => {
        const storedToken = JSON.parse(localStorage.getItem("access_token"));
        if (storedToken == null) {
            history.push("/");
        }
    }, [storedToken]);
    return (
        <>
            <div className="bg-chatBot-theme theme-body">
                <Navbar />
                <h4 className="text-light text-center mt-3">
                    {capitalizedPathname}
                </h4>
                <Container className="main-body">
                    <Row>
                        <Col>{props.children}</Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Main;
