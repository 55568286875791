import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import fileshare from "../assets/img/file-share.png";
import chatting from "../assets/img/chatting.png";
import eye from "../assets/img/eye.png";
export default function Library() {
    const history = useHistory();
    const chatBotDetail = [
        {
            company: "@Abccompany",
            context: "000",
            Message: "000",
            View: "000",
            createdAt: "26/09/2023",
        },
    ];
    return (
        <Container className="library-section">
            <Button
                className="bg-item-bg px-3 mt-3"
                onClick={() => {
                    history.push("/train");
                }}
            >
                Create Your ChatBot
            </Button>
            {/* <Row className="d-flex align-items-center justify-content-center">
                <Col md={6}>
                    <Card className="bg-card-background shadow mt-5 mb-5">
                        <CardBody>
                            <h3 className="text-center text-light mt-3">
                                No ChatBots Created Yet
                            </h3>
                            <div className="d-flex align-items-center justify-content-center mb-3 mt-3">
                                <Button
                                    className="rounded-pill bg-light text-dark px-4 mt-2"
                                    onClick={() => {
                                        history.push("/train");
                                    }}
                                >
                                    CREATE YOUR FIRST CHATBOT
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}
            {chatBotDetail?.map((item) => {
                return (
                    <Row className="my-4 ">
                        <Col>
                            <Card className="bg-card-background shadow">
                                <CardBody>
                                    <Row className="my-3">
                                        <Col lg={10} sm={8}>
                                            <h5 className="text-light">
                                                {item?.company}
                                            </h5>
                                        </Col>
                                        <Col lg={2} sm={4}>
                                            <Button className="rounded-pill bg-light text-dark px-3 ">
                                                Share{" "}
                                                <i className="fas fa-share"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4} className="mt-1">
                                            <Card className="bg-chatBot-theme">
                                                <CardBody>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src={
                                                                        fileshare
                                                                    }
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="graph-text">
                                                                <h5 className="text-light">
                                                                    {
                                                                        item?.context
                                                                    }
                                                                </h5>
                                                                <h5 className="text-light">
                                                                    Context
                                                                </h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={4} className="mt-1">
                                            <Card className="bg-chatBot-theme">
                                                <CardBody>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src={
                                                                        chatting
                                                                    }
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="graph-text">
                                                                <h5 className="text-light">
                                                                    {
                                                                        item?.Message
                                                                    }
                                                                </h5>
                                                                <h5 className="text-light">
                                                                    Messages
                                                                </h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={4} className="mt-1">
                                            <Card className="bg-chatBot-theme">
                                                <CardBody>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src={eye}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="graph-text">
                                                                <h5 className="text-light">
                                                                    {item?.View}
                                                                </h5>
                                                                <h5 className="text-light">
                                                                    View
                                                                </h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <Button className="library-button rounded-pill border-0  bg-light text-dark mx-2">
                                                LAUNCH
                                            </Button>
                                            <Button className=" library-button rounded-pill border-0  bg-light text-dark mx-2">
                                                ADD CONTEXT
                                            </Button>
                                            <Button className="library-button rounded-pill border-0  bg-light text-dark mx-2">
                                                PREVIEW
                                            </Button>
                                            <Button className="library-button rounded-pill border-0  bg-light text-dark mx-2">
                                                EDIT
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2 ">
                                        <Col sm={10}>
                                            <h6 className="text-light">
                                                {item?.createdAt}
                                            </h6>
                                        </Col>
                                        <Col sm={2}>
                                            <h6 className="text-light">
                                                Delete
                                            </h6>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                );
            })}
        </Container>
    );
}
