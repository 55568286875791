import AuthView from "../views/auth/AuthView";
import Home from "../views/Home";
import Library from "../views/Library";
import Train from "../views/Train";
import CreatedSuccessfully from "../components/TrainSteps/CreatedSuccessfully";
import Analytics from "../views/Analytics";
import Credits from "../views/Credits";
import Profile from "../views/Profile";
let routes = [
    {
        path: "/auth",
        component: AuthView,
        layout: "auth",
    },
    {
        path: "/",
        component: Home,
        layout: "main",
    },
    {
        path: "/library",
        component: Library,
        layout: "ChatBot",
    },
    {
        path: "/train",
        component: Train,
        layout: "ChatBot",
    },
    {
        path: "/createdSuccess",
        component: CreatedSuccessfully,
        layout: "ChatBot",
    },
    {
        path: "/analytics",
        component: Analytics,
        layout: "ChatBot",
    },
    {
        path: "/credits",
        component: Credits,
        layout: "ChatBot",
    },
    {
        path: "/profile",
        component: Profile,
        layout: "ChatBot",
    },
];
export default routes;
