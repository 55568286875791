// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import trainReducer from "./trainReducer";
export let rootReducer = combineReducers({
    authUser: authUserReducer,
    train: trainReducer,
});

export default rootReducer;
