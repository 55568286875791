import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Input, Row } from "reactstrap";
import { checkUsernameAction } from "../../store/actions/trainAction";

export default function UserName({
    chatbotDetail,
    setChatbotDetail,
    currentStep,
    setCurrentStep,
}) {
    const dispatch = useDispatch();
    const { isValidUsername } = useSelector((state) => state.train);
    const handleInputChange = (e) => {
        const newValue = e.target.value;
        // Check if the new value starts with the initial part 'DroidBot.com/'
        if (newValue.startsWith("DroidBot.com/")) {
            setChatbotDetail({ ...chatbotDetail, username: newValue });
        } else {
            // If not, keep the initial part and append the new value
            setChatbotDetail({
                ...chatbotDetail,
                username: "DroidBot.com/" + newValue,
            });
        }
    };
    const handleusername = () => {
        const parts = chatbotDetail?.username?.split("/");
        if (parts[1] == "") {
            alert("Username is mendatory!");
        } else {
            dispatch(checkUsernameAction(parts[1]));
        }
    };
    useEffect(() => {
        const parts = chatbotDetail?.username?.split("/");
        if (parts[1] != "") {
            if (isValidUsername == false) {
                let step = currentStep + 1;
                setCurrentStep(step);
            }
        }
    }, [isValidUsername]);
    return (
        <Container>
            <Row>
                <Col lg={8} md={12}>
                    <div className="right-section my-2 d-flex align-items-center">
                        <div className="mx-3">
                            <h3 className="text-light font-heading">Username</h3>
                            <p className="text-light font-small">
                                Enter A Username To Represent Your Brand
                            </p>
                            <Input
                                type="text"
                                className="username-input border-0 text-light"
                                value={chatbotDetail?.username}
                                onChange={(e) => {
                                    handleInputChange(e);
                                }}
                            />
                            <Button
                                className="rounded-pill px-5 bg-item-bg text-dark mt-4"
                                onClick={() => {
                                    handleusername();
                                }}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={4} md={12}>
                    <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                        <div className="chatbot-screen">
                            <h4 className="fs--20 mt-3 mx-2">PhDBot</h4>
                            {/* Content of chatbot screen */}
                            {/* ... */}
                            <div className="rounded-pill-footer">
                                <input
                                    type="text"
                                    className="rounded-pill-input px-1"
                                    placeholder="Ask me anything"
                                />
                                <Button className="rounded-pill-button">
                                    Send<i class="mx-1 fas fa-share"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
