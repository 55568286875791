import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PaymentPlan from "../components/PaymentPlan";

export default function Credits() {
    return (
        <Container fluid>
            <Row>
                <Col md={4}>
                    <Card className="bg-chatBot-theme p-0 mt-3 mb-4">
                        <CardBody>
                            <div className="d-flex align-items-center justify-content-center">
                                <div>
                                    <h3 className="text-center text-item-bg">4/50</h3>
                                    <p className="text-center text-light fs--10">
                                        Credits Used This Month:
                                    </p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="bg-chatBot-theme p-0 mt-3 mb-4">
                        <CardBody>
                            <div className="d-flex align-items-center justify-content-center">
                                <div>
                                    <h3 className="text-center text-item-bg">Free</h3>
                                    <p className="text-center text-light fs--10">
                                        Current Plan:
                                    </p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <PaymentPlan background="bg-chatBot-theme"/>
        </Container>
    );
}
