const initialState = {
    isValidUsername: null,
};

const trainReducer = (state = initialState, action) => {
    switch (action.type) {
        case "checkUsername":
            return {
                ...state,
                isValidUsername: action.payload,
            };

        case "clearUsername":
            return {
                ...state,
                isValidUsername: action.payload,
            };
        default:
            return state;
    }
};

export default trainReducer;
