import React from "react";
import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import share from "../assets/img/share.png";
import add from "../assets/img/add.png";
import money from "../assets/img/money.png";
export default function Profile() {
    return (
        <Container fluid>
            <Row>
                <Col md={5}>
                    <Card className="bg-chatBot-theme mt-3 mb-3">
                        <CardBody>
                            <h3 className="text-light fs--17">
                                Account Details
                            </h3>
                            <Input
                                placeholder="Email"
                                className="bg-chatBot-theme mt-2"
                            />
                            <Input
                                placeholder="Name"
                                className="bg-chatBot-theme mt-2"
                            />
                            <Button className="bg-item-bg rounded-pill mt-2 px-5 text-dark">
                                Update Profile
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={5}>
                    <Card className="bg-chatBot-theme mt-3 mb-3">
                        <CardBody>
                            <h3 className="text-center text-light fs--17">
                                How it Works{" "}
                            </h3>
                            <Row>
                                <Col md={4}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div>
                                            <div className="rounded-circle bg-item-bg">
                                                <i className="fa-solid fas fa-share p-3"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="fs--10 text-center text-light">
                                        Share Your Unique Referral Links.
                                    </p>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div>
                                            <div className="rounded-circle bg-item-bg">
                                                <i className="fa-solid fas fa-user-plus p-3"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="fs--10 text-center text-light">
                                        Your Cantact Uses It To Create A
                                        Chatbot.
                                    </p>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div>
                                            <div className="rounded-circle bg-item-bg">
                                                <img
                                                    src={money}
                                                    className="p-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="fs--10 text-center text-light">
                                        We'll Give You 25% Forever!
                                    </p>
                                </Col>
                            </Row>
                            <div className="d-flex align-items-center justify-content-center ">
                                <Button className="bg-item-bg text-dark rounded-pill px-4">
                                    Start Earning
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
