import React, { useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import views from "../assets/img/Views.png";
import thumbs from "../assets/img/Thumbs.png";
import message from "../assets/img/Message.png";
import rate from "../assets/img/Rate.png";
import classnames from 'classnames'; 
export default function Analytics() {
    const analyticsDetail = [
        { name: "Views", value: "000", image: views },
        { name: "Thumbs Up", value: "000", image: thumbs },
        { name: "Message Received", value: "000", image: message },
        { name: "Ask Rate", value: "000", image: rate },
    ];
    const [currentActiveTab, setCurrentActiveTab] = useState("1");
    const toggle = (tab) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    };
    return (
        <Container fluid>
            <Row>
                {analyticsDetail?.map((item) => {
                    return (
                        <Col lg={2}>
                            <Card className="bg-chatBot-theme p-0 mt-3 mb-4">
                                <CardBody>
                                    <div className="d-flex align-items-center justify-content-center ">
                                        <div className="h-100">
                                            <img src={item?.image} />
                                        </div>
                                    </div>
                                </CardBody>
                                <h4 className="text-light fs--15 text-center">
                                    {item?.value}
                                </h4>
                                <p className="text-light fs--10 text-center">
                                    {item?.name}
                                </p>
                            </Card>
                        </Col>
                    );
                })}
                <Col lg={4}>
                    <Card className="bg-chatBot-theme mt-3 p-0 mb-4 tab-card">
                        <CardBody>
                            <h3 className="text-center text-light fs--15">
                                Tools
                            </h3>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={`text-secondary fs--10 ${classnames({
                                            active: currentActiveTab === "1",
                                        })}`}
                                        onClick={() => {
                                            toggle("1");
                                        }}
                                    >
                                        Fine-Tune
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                          className={`text-secondary fs--10 ${classnames({
                                            active: currentActiveTab === "2",
                                        })}`}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        Explore Clusters
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                           className={`text-secondary fs--10 ${classnames({
                                            active: currentActiveTab === "3",
                                        })}`}
                                        onClick={() => {
                                            toggle("3");
                                        }}
                                    >
                                        Intent Search
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={currentActiveTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <h5 className="text-light">Sample Tab 1 Content</h5>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <h5 className="text-light">Sample Tab 2 Content</h5>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>
                                        <Col sm="12">
                                            <h5 className="text-light">Sample Tab 3 Content</h5>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
