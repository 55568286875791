import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import user from "../assets/img/user.png";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
export default function Navbar() {
    const history = useHistory();
    const { user } = useSelector((state) => state.authUser);
    const [activeLink, setActiveLink] = useState("/library");
    const location = useLocation();
    const handleLinkClick = (linkName) => {
        setActiveLink(linkName);
        history.push(linkName);
    };

    const isLinkActive = (linkName) => {
        return linkName === activeLink;
    };

    const getLinkContent = (text, iconClass, linkName) => {
        const isActive = isLinkActive(linkName);

        return (
            <li className={`nav-item  px-4 ${isActive ? "bg-item-bg" : ""}`}>
                <div
                    className={` text-light nav-link ${
                        isActive ? "active" : ""
                    }`}
                    aria-current="page"
                    onClick={() => handleLinkClick(linkName)}
                >
                    <i className={iconClass}></i>
                    {isActive && <span className="mx-1">{text}</span>}
                </div>
            </li>
        );
    };
    useEffect(() => {
        if (location?.pathname == "/library") {
            getLinkContent("Library", "fas fa-book", "/library");
            handleLinkClick(location?.pathname);
        } else if (location?.pathname == "/analytics") {
            getLinkContent("Analytics", "fas fa-chart-line", "/analytics");
            handleLinkClick(location?.pathname);
        } else if (location?.pathname == "/credits") {
            getLinkContent("Credits", "fas fa-money-check", "/credits");
            handleLinkClick(location?.pathname);
        } else if (location?.pathname == "/train") {
            getLinkContent("Train", "fas fa-plus-circle", "/train");
            handleLinkClick(location?.pathname);
        }
    }, [location?.pathname]);
    return (
        <>
            <Container className="bg-navbar-color navbar-main">
                <Row>
                    <nav className="navbar navbar-expand-md text-light">
                        <div className="container-fluid">
                            <a className="navbar-brand text-light" href="#">
                                DroidBot
                            </a>
                            <button
                                className="navbar-toggler text-light"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i className="fas fa-solid fa-bars"></i>
                            </button>
                            <div
                                className="collapse navbar-collapse"
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    {getLinkContent(
                                        "Library",
                                        "fas fa-book",
                                        "/library"
                                    )}
                                    {getLinkContent(
                                        "Analytics",
                                        "fas fa-chart-line",
                                        "/analytics"
                                    )}
                                    {getLinkContent(
                                        "Credits",
                                        "fas fa-money-check",
                                        "/credits"
                                    )}
                                    {getLinkContent(
                                        "Profile",
                                        "fas fa-user-circle",
                                        "/profile"
                                    )}
                                    {getLinkContent(
                                        "Train",
                                        "fas fa-plus-circle",
                                        "/train"
                                    )}
                                </ul>
                            </div>
                            <span className="profile-avatar avatar avatar-sm rounded-circle">
                                <img
                                    src={user?.imageURL}
                                    alt="1"
                                    className="rounded-circle mx-2"
                                    height={40}
                                />
                                {user?.name}
                            </span>
                        </div>
                    </nav>
                </Row>
            </Container>
        </>
    );
}
