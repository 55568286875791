import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
export default function SuggestionQuestions({
    chatbotDetail,
    setChatbotDetail,
    currentStep,
    setCurrentStep
}) {
    const handleAddSuggestion = () => {
        setChatbotDetail({
            ...chatbotDetail,
            suggestionQuestions: [...chatbotDetail?.suggestionQuestions, ""],
        });
    };

    const handleRemoveSuggestion = (index) => {
        const updatedSuggestions = [...chatbotDetail?.suggestionQuestions];
        updatedSuggestions.splice(index, 1);
        setChatbotDetail({
            ...chatbotDetail,
            suggestionQuestions: updatedSuggestions,
        });
    };

    const handleSuggestionChange = (index, newValue) => {
        const updatedSuggestions = [...chatbotDetail?.suggestionQuestions];
        updatedSuggestions[index] = newValue;
        setChatbotDetail({
            ...chatbotDetail,
            suggestionQuestions: updatedSuggestions,
        });
    };
    const handleSuggestionQuestionNext = () => {
        let check  =  chatbotDetail.suggestionQuestions.join("")
        if (check != "") {
            let step = currentStep + 1;
            setCurrentStep(step);
        } else {
            alert("Suggesstion Questions is mendatory!");
        }
    };
    const handleBack = () => {
        let step = currentStep - 1;
        setCurrentStep(step);
    };
    return (
        <Container>
            <Row>
                <Col lg={8} md={12}>
                    <div className="right-section-chat my-2 d-flex align-items-center">
                        <div className="mx-3">
                        <p
                                className="text-item-bg mt-2 cursor"
                                onClick={() => {
                                    handleBack();
                                }}
                            >
                                <i class="fa-solid fas fa-arrow-left"></i> Back
                            </p>
                            <h3 className="text-light font-heading">Suggestion Questions</h3>
                            <p className="text-light font-small">
                                Guide User Interactions With Helpful Prompts And
                                Inquiries.
                            </p>
                            {chatbotDetail?.suggestionQuestions.map(
                                (suggestion, index) => (
                                    <div key={index} className="d-flex">
                                        <Input
                                            type="text"
                                            className="username-input border-0 text-light"
                                            placeholder="Enter Suggestion..."
                                            value={suggestion}
                                            onChange={(e) =>
                                                handleSuggestionChange(
                                                    index,
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <Button
                                            className="bg-danger mt-3 mx-1 "
                                            onClick={() =>
                                                handleRemoveSuggestion(index)
                                            }
                                        >
                                            <i className="fas fa-trash" />
                                        </Button>
                                    </div>
                                )
                            )}
                            {/* Plus icon to add a new suggestion input */}
                            <Button
                                className="bg-success mt-2"
                                onClick={handleAddSuggestion}
                            >
                                <i className="fas fa-plus" />
                            </Button>
                            <Button
                                className="d-block rounded-pill px-5 bg-item-bg text-dark mt-4 font-small"
                                onClick={() => {
                                    handleSuggestionQuestionNext();
                                }}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={4} md={12}>
                    <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                        <div className="chatbot-screen">
                            <h4 className="fs--20 mt-3 mx-2">PhDBot</h4>
                            <div className="chat-container">
                                {chatbotDetail?.chatBotFor?.detail?.map(
                                    (message, index) => (
                                        <div
                                            key={index}
                                            className={`message ${
                                                index % 2 === 0 ? "user" : "bot"
                                            }`}
                                        >
                                            {index % 2 === 0 ? (
                                                <div className="user-message shadow-sm">
                                                    {message.user}
                                                </div>
                                            ) : (
                                                <div className="bot-message shadow-sm">
                                                    {message.bot}
                                                </div>
                                            )}
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="rounded-pill-footer">
                                <input
                                    type="text"
                                    className="rounded-pill-input px-1"
                                    placeholder="Ask me anything"
                                />
                                <Button className="rounded-pill-button">
                                    Send<i class="mx-1 fas fa-share"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
