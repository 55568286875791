// chrome.exe --user-data-dir="C://Chrome dev session" --disable-web-security
import axios from "axios";
const baseDomain = "http://192.168.18.86:8000/";
const baseURL = `${baseDomain}`;
const getToken = () => {
    try {
        const tokenString = JSON.parse(localStorage.getItem("access_token"));
        if (tokenString) {
            return tokenString;
        }
    } catch (error) {
        console.log("Error parsing localStorage:", error);
    }
    return "";
};
let axiosObj;
axiosObj = axios.create({
    baseURL,
    headers: { "Content-Type": "application/json", "token": getToken() },
});
export default axiosObj;
